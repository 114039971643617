import React from "react";
import BannerRow from "../BannerRow/BannerRow";
import TemplateBannerRow from "../TemplateBannerRow/TemplateBannerRow";
import { BannerComponentDataRowSubTypeEnums } from "../BannerRow/contants";
import SplitBannerRow from "../SplitBannerRow/SplitBannerRow";

const BannerController = ({ row, merchantDetail, index, hbAdultValue, backgroundType, isBot }) => {
  const filteredRowData = row.data.filter(banner => banner.tags !== null);

  if (row.subType === BannerComponentDataRowSubTypeEnums.SPLIT_BANNER) {
    const bannerHasUnavailableTags = row.data.some(banner => banner.tags === null);
    return bannerHasUnavailableTags ? (
      <></>
    ) : (
      <SplitBannerRow key={row.id} row={row} merchantDetail={merchantDetail} index={index} />
    );
  }
  if (row.subType === BannerComponentDataRowSubTypeEnums.TEMPLATE_BANNER)
    return (
      <TemplateBannerRow
        key={row.id}
        row={{ ...row, data: filteredRowData }}
        merchantDetail={merchantDetail}
        index={index}
      />
    );
  return (
    <BannerRow
      key={row.id}
      row={{ ...row, data: filteredRowData }}
      merchantDetail={merchantDetail}
      index={index}
      hbAdultValue={hbAdultValue}
      backgroundType={backgroundType}
      isBot={isBot}
    />
  );
};

export default BannerController;
